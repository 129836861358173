.card {
    background: var(--surface-card);
    //padding: 1.5rem;
    padding: 5px;
    margin-bottom: 1rem;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 24px 36px rgba(0, 0, 0, 0.04);
    //border-radius: 14px;
    border-radius: 6px;

    &:last-child {
        margin-bottom: 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 7rem;
    }
}

.p-menubar .p-submenu-list{
    z-index: 1000;
}

//////////////////CUSTOMCSS-----------------------------------------------////

.col-12{
    padding: 5px !important;
}
.header-left-div {
    display: flex;
    gap: 20px;
    align-items: center;
}

.header-icon {
    height: 55px;
    border-radius: 5px;
    padding: 2px 0 2px 0;
    filter: invert(100%);
}

.header-heading {
    line-height: 0;
    font-size: 15px;
    margin: 15px 0 20px 0;
}

.header-div2{
    h5{
        margin: 0;
    }
}

.view-container {
    padding: 1rem;
}

.view-container1{
    label{
        margin: 0;
        align-items: center;
    }
}

.view-main {
    position: relative; 
    border: 1px solid #dfe4ea;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: box-shadow 0.5s ease, transform 0.5s ease;
}

.view-main::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    box-shadow: 0 0 0 rgba(255, 0, 0, 0);
    opacity: 0;
    transition: opacity 0.5s ease, box-shadow 0.5s ease;
    z-index: -1;
}

.view-main:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    transform: scale(1.0);
}

.view-main:hover::before {
    opacity: 1;
    box-shadow: 0 0 12px rgba(76, 99, 182, 0.5);
}

.view-content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.view-icon {
    font-size: 2rem;
    color: #4C63B6;
}

.view-icon-blue {
    font-size: 2rem;
    color: #2196F3;
}

.view-icon-green {
    font-size: 2rem;
    color: #228B22; /* Forest Green */
}

.view-icon-skyblue {
    font-size: 2rem;
    color: #00BFFF; /* Deep Sky Blue */
}

.view-icon-red {
    font-size: 2rem;
    color: #DC143C; /* Crimson Red */
}

.view-icon-goldenrod {
    font-size: 2rem;
    color: #DAA520; /* Goldenrod */
}

.view-icon-gray {
    font-size: 2rem;
    color: #708090; /* Slate Gray */
}

.view-icon-coral {
    font-size: 2rem;
    color: #FF7F50; /* Coral */
}

.view-icon-royalblue {
    font-size: 2rem;
    color: #4169E1; /* Royal Blue */
}

.view-icon-orange {
    font-size: 2rem;
    color: #FF8C00; /* Dark Orange */
}

.view-icon-olivedrab {
    font-size: 2rem;
    color: #6B8E23; /* Olive Drab */
}

.view-icon-violetred {
    font-size: 2rem;
    color: #C71585; /* Medium Violet Red */
}

.view-icon-steelblue {
    font-size: 2rem;
    color: #4682B4; /* Steel Blue */
}

.view-icon-saddlebrown {
    font-size: 2rem;
    color: #8B4513; /* Saddle Brown */
}

.view-icon-indianred {
    font-size: 2rem;
    color: #CD5C5C; /* Indian Red */
}

.view-icon-seagreen {
    font-size: 2rem;
    color: #20B2AA; /* Light Sea Green */
}

.view-icon-peru {
    font-size: 2rem;
    color: #CD853F; /* Peru */
}

.view-icon-tomato {
    font-size: 2rem;
    color: #FF6347; /* Tomato */
}

.view-icon-turquoise {
    font-size: 2rem;
    color: #40E0D0; /* Turquoise */
}

.view-icon-orchid {
    font-size: 2rem;
    color: #DA70D6; /* Orchid */
}

.view-icon-chocolate {
    font-size: 2rem;
    color: #D2691E; /* Chocolate */
}

.view-icon-firebrick {
    font-size: 2rem;
    color: #B22222; /* Firebrick */
}

.view-label {
    font-weight: 500;
    color: #444;
    margin-bottom: 0.5rem;
}

.view-value {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    max-width: 100%;
}

@media (max-width: 768px) {
    .view-content {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .view-label {
        margin-bottom: 0rem;
    }
    
    .view-value {
        margin-top: 0.2rem;
    }
}

.no-response-message {
    padding: 10px;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    border-radius: 4px;
    margin-top: 10px;
}